/* Main Container */
.wallet-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    /* background: #f4f6f9; */
    font-family: 'Arial', sans-serif;
    padding: 20px;
  }
  
  /* Wallet Card */
  .wallet-card {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 400px;
    text-align: center;
    margin-bottom: 30px;
  }
  
  /* Wallet Header */
  .wallet-header {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Balance */
  .balance {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #860a0a;
  }
  
  /* Transaction Button */
  .transaction-btn {
    background-color:  #860a0a;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
  }
  
  .transaction-btn:hover {
    background-color: #ed497f;
  }
  
  /* Transaction History */
  .transaction-history {
    width: 90%;
    max-width: 400px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  /* Transaction History Header */
  .history-header {
    font-size: 20px;
    margin-bottom: 15px;
    color: #333;
  }
  
  /* Transaction Item */
  .history-list {
    margin-top: 10px;
  }
  
  .transaction {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid #eee;
  }
  
  .transaction-date {
    color: #555;
  }
  
  .transaction-amount {
    font-weight: bold;
    font-size: 18px;
  }
  
  .transaction-amount.credit {
    color: #27ae60;
  }
  
  .transaction-amount.debit {
    color: #e74c3c;
  }
  